import React, { useEffect, useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Masonry from "react-masonry-css";
import ImageViewer from "../layout/imageViewer";
import "antd/dist/antd.css";
import "../assets/styles.scss";
import Main from "../layout/layout";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { Trans,Link, useTranslation } from "gatsby-plugin-react-i18next";
import { Row, Col } from "antd";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../layout/seo";

// markup
const IndexPage = ({ location, data }) => {
  const { t } = useTranslation();

  const entries = data.allContentfulFeaturedWork.edges;
  const [filteredImages, setFilteredImages] = useState([]);
  const [viewerVisible, setViewerVisible] = useState(false);
  const [photoVisible, setPhotoVisible] = useState(false);
  const [indexPhotoVisble, setIndexPhotoVisible] = useState(0);

  useEffect(() => {
    let imgs = [];

    for (let entry of entries) {
      for (let img of entry.node.photos) {
        imgs.push({ image: img });
      }
    }



    setFilteredImages(imgs);
  }, [entries]);

  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 2,
    500: 1,
  };

  const toggleImageViewer = () => {
    if (viewerVisible === false) {
      document.body.classList.add("viewer-visibile");
    } else {
      document.body.classList.remove("viewer-visibile");
    }
    setViewerVisible(!viewerVisible);
  };

  const previous = () => {
    let key = indexPhotoVisble;
    if (key > 0) {
      key = indexPhotoVisble - 1;
    }
    setIndexPhotoVisible(key);
    setPhotoVisible(filteredImages[key].image);
  };

  const next = () => {
    let key = indexPhotoVisble;
    if (key < filteredImages.length - 1) {
      key = indexPhotoVisble + 1;
    }
    setIndexPhotoVisible(key);
    setPhotoVisible(filteredImages[key].image);
  };

  const openPhoto = (photo, key) => {
    setPhotoVisible(photo);
    setIndexPhotoVisible(key);
    toggleImageViewer();
  };
  return (
    <main>
      <SEO title="Victor Chevalier - Visual artist" description={t("Description about")}></SEO>
      <Main location={location}>
        {viewerVisible ? (
          <ImageViewer
            close={toggleImageViewer}
            previous={indexPhotoVisble === 0 ? false : previous}
            next={indexPhotoVisble === filteredImages.length - 1 ? false : next}
            image={photoVisible}
          ></ImageViewer>
        ) : (
          <></>
        )}
        <div className="main-container" style={{ marginTop: "2rem" }}>
          {/* <Row  style={{justifyContent:"end"}} className="center-row w-100">
     
              <StaticImage
                style={{width:"5%"}}
                className="logo-home"
                quality={90}
                src="../images/logo.png"
                alt="Logo of Victor Chevalier"
              /> */}
              {/* <p className="topics-home"><Link to="/streetart">STREET ART</Link> | <Link to="/photos">PHOTOGRAPHY</Link> | <Link to="/about">FILM</Link></p> */}
              {/* <p className="desc-home">
                <Trans>Bio home</Trans>
              </p> */}
    

            {/* <Col className="h100" xs={24} lg={8}>
              <StaticImage
                className="video-home"
                quality={90}
                src="../images/victorchevalier.jpg"
                alt="Portrait of Victor Chevalier"
              />
            </Col> */}
      
          {/* </Row> */}
          <div
            style={{ marginBottom: "2rem", marginTop: "2rem" }}
            className="title-page"
          >
            <Trans>Featured</Trans>
            <div className="hr"></div>
          </div>

          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {filteredImages.map((img, key) => {
              let image = getImage(img.image);

              return (
                <div onClick={() => openPhoto(img.image, key)}>
                  <GatsbyImage
                    objectFit="cover"
                    style={{ cursor: "pointer" }}
                    image={image}
                    alt={""}
                  />
                </div>
              );
            })}
          </Masonry>
        </div>
      </Main>
    </main>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query langSinglesIndex($language: String!) {
    allContentfulFeaturedWork {
      edges {
        node {
          id
          name
          photos {
            title
            createdAt
            gatsbyImageData(
              width: 1500
              placeholder: DOMINANT_COLOR
              formats: [AUTO, AVIF, WEBP]
            )
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
